<template>
    <default-layout>
        <div class="spainhn-solid-red-background d-flex" style="min-height:8vh; width:100%">
            <div class="d-flex ps-5 my-auto">
                <p class="my-auto pb-0 mb-0 spainhn-text" role="button">{{ $t('default.CART_DESCRIPTION_TEXT') }} >></p>
                <p class="my-auto pb-0 mb-0 ms-2 spainhn-small-text"><strong>{{ $t('default.CART_RESUME_TEXT')}}</strong></p>
            </div>
        </div>

        <div class="container-fluid">
            <div id="loader" :class="{'d-none': (!processing && !sending) }" class="loader-component">
                <img src="../assets/SimboloCastilloRojoCorporativa.svg" style="height:220px; width:auto;" class="animated flip infinite"/>
                <!-- <h5>Cargando...</h5> -->
            </div>

            <mdb-row class="px-md-4 py-2">
                <reserva-item v-for="(item, index) in items" :item="item" v-bind:key="index"
                            @itemRemoved="goToCalendar" @eliminando="(evt) => sending = evt"/>
                <div class="clearfix mt-2">
                    <div class="float-right">
                        <h6 class="text-end fw-bold" :class="{'d-none':descuentoYaAplicado === 0}">{{ $t('default.MENSAJE_IMPORTE_SUBTOTAL') }} </h6>
                        <h6 class="text-end fw-bold text-decoration-line-through" :class="{'d-none':descuentoYaAplicado === 0}">{{(totalAPagar + (descuentoYaAplicado ?? 0 )).toLocaleString('es-ES', { style:'currency', currency: 'EUR' } )}}</h6>
                        <h6 class="text-end fw-bold" :class="{'d-none':descuentoYaAplicado === 0}">{{ $t('default.MENSAJE_IMPORTE_DESCUENTO') }} </h6>
                        <h6 class="text-end fw-bold" :class="{'d-none':descuentoYaAplicado === 0}">{{(descuentoYaAplicado).toLocaleString('es-ES', { style:'currency', currency: 'EUR' } )}}</h6>
                        <h4 class="text-end fw-bold">{{ $t('default.MENSAJE_IMPORTE_TOTAL') }}</h4>
                        <h3 class="text-end fw-bold">{{(totalAPagar).toLocaleString('es-ES', { style:'currency', currency: 'EUR' } )}}</h3>
                    </div>
                </div>
            </mdb-row>

                <mdb-row class="justify-content-md-center">
                    <mdb-col col="12" class="pt-3">
                        <!-- <div class="spainhn-subtitle-2-black text-start ps-md-5 ps-2">{{ $t('default.CART_RESUME') }}</div> -->

                        <!-- <h3 class="text-end">{{ $t('default.MENSAJE_IMPORTE_TOTAL') }} {{
                                (totalAPagar).toLocaleString('es-ES', { 'maximumFractionDigits': 2 })}} €
                        </h3> -->

                        <div v-bind:class="{ 'd-none': !cancelable }">
                            <div v-bind:class="{ 'd-none': (seguroDeCancelacion) }">
                                <div class="border px-5 py-4 border-warning mb-3"
                                    style="background-color:rgba(252, 248, 134, 0.5)">
                                    <div class="row">
                                        <div class="col-md-1 col-12 my-auto">
                                            <div class="d-flex">
                                                <font-awesome-icon class="my-auto text-danger mx-auto"
                                                    icon="fa-circle-exclamation" size="3x"></font-awesome-icon>
                                            </div>
                                        </div>
                                        <div class="col-10 mx-auto">
                                            <p><strong class="text-spainhn">{{ $t('default.MENSAJE_IMPREVISTO') }}</strong>
                                            </p>
                                            <p class="text-spainhn"> {{ $t('default.MENSAJE_SIN_GARANTIA') }} </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="border px-5 py-4 border-info mt-3 mb-3"
                                    style="background-color:rgba(204, 255, 255, 0.5)">
                                    <div class="row text-start">
                                        <div class="col-md-8 col-12 text-center">
                                            <p><strong class="spainhn-subtitle-2-black">{{
                                                    $t('default.MENSAJE_TITULO_GARANTIA')
                                            }}</strong></p>
                                            <p class="text-spainhn">{{ $t("default.MENSAJE_EXPLICACION_DEVOLUCION") }}</p>
                                        </div>

                                        <div class="col-md-4 col-12 text-center">
                                            <p class="spainhn-text" style="font-weight:600">{{
                                                    $t("default.MENSAJE_GARANTIA_2")
                                            }}</p>
                                            <p class="spainhn-subtitle-2-black">{{ totalCancelacion }} €</p>
                                            <mdb-btn color="indigo" class="spainhn-button" @click="agregarSeguro"><strong>{{
                                                    $t("default.MENSAJE_AGREGAR_GARANTIA")
                                            }}</strong></mdb-btn>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-bind:class="{ 'd-none': (!seguroDeCancelacion) }">
                                <div class="border px-md-5 px-3 py-4 border-success mb-3"
                                    style="background-color:rgba(204, 255, 204, 0.5)">
                                    <div class="row">
                                        <div class="col-md-1 col-12 my-auto">
                                            <div class="d-flex">
                                                <font-awesome-icon class="my-auto mx-auto text-success"
                                                    icon="fa-circle-check" size="3x"></font-awesome-icon>
                                            </div>
                                        </div>
                                        <div class="col-10 mx-auto">
                                            <p><strong class="text-spainhn">{{
                                                    $t("default.MENSAJE_TITULO_CONFIRMACION_GARANTIA")
                                            }}</strong></p>
                                            <p class="text-spainhn">{{ $t("default.MENSAJE_IMPORTE_GARANTIA") }}
                                                <strong>{{ totalCancelacion }} €</strong></p>
                                        </div>
                                    </div>
                                </div>

                                <div class="border px-md-5 px-3 py-4 border-info mt-3 mb-3"
                                    style="background-color:rgba(204, 255, 255, 0.5)">
                                    <div class="row text-start">
                                        <div class="col-md-8 col-12 text-center">
                                            <p><strong class="spainhn-subtitle-2-black">{{
                                                    $t("default.MENSAJE_TITULO_GARANTIA")
                                            }}</strong></p>
                                            <p class="text-spainhn">{{ $t("default.MENSAJE_EXPLICACION_DEVOLUCION") }}</p>
                                        </div>

                                        <div class="col-md-4 col-12 text-center my-auto" role="button"
                                            @click="quitarSeguro">
                                            <div class="mb-2">
                                                <font-awesome-icon icon="fa-trash" size="3x"></font-awesome-icon>
                                            </div>
                                            <small>{{ $t("default.MENSAJE_QUITAR_GARANTIA") }}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </mdb-col>
                </mdb-row>


            <mdb-row v-bind:class="{ 'd-none': !existItemsInCart}">
                <div class="col-12 spainhn-text mt-5">
                    <formulario-compra-horizontal @dataReady="sendToRedsysV2"></formulario-compra-horizontal>
                </div>
            </mdb-row>

                <!-- <div class="col-lg-4 col-xl-3 spainhn-text text-start mt-md-5">
                    <formulario-compra-horizontal></formulario-compra-horizontal>
                </div> -->

        </div>



        <div class="container-fluid spainhn-bg-gris-cajas w-100 py-5 px-4 my-4 rounded"
            v-bind:class="{ 'd-none': items.length }">
            <div class="spainhn-subtitle">{{ $t('default.CART_EMPTY_CART') }}</div>
            <div>
                <mdb-btn size="lg" color="danger" @click="() => $router.push('/')"> <span class="fw-bold">{{ $t('default.CART.BUY_MORE') }}</span></mdb-btn>
            </div>
        </div>

        <b-modal v-model="redireccionar" aria-label="" hide-header hide-footer>
            <div class="border px-2 py-4 border-success mb-3" style="background-color:rgba(204, 255, 204, 0.5)">
                <div class="d-flex">
                    <div class="d-flex mx-auto">
                        <!-- <font-awesome-icon class="my-auto text-success me-2" icon="fa-circle-check" size="3x"></font-awesome-icon> -->
                        <p class="my-auto">
                            <strong class="text-spainhn">
                                {{ $t("default.CART.REDIRECT") }}
                            </strong>
                        </p>
                    </div>
                </div>
            </div>
        </b-modal>

        <b-modal @ok="quitarReservas">
            <div>
                {{ $t('default.CART_REMOVE_ALL') }}
            </div>
        </b-modal>

        <b-form name="redsys_form" :action="redsysUrl" method="post" id="redsys_form" ref="redsys_form">
            <input type="text" name="Ds_SignatureVersion" v-model="Ds_SignatureVersion" hidden />
            <input type="text" name="Ds_MerchantParameters" v-model="Ds_MerchantParameters" hidden />
            <input type="text" name="Ds_Signature" v-model="Ds_Signature" hidden />
        </b-form>

        <error-alert :show="itemRepetido" @close="() => itemRepetido = false"
            :mensaje="$t('default.CART.DUPLICATED')">
        </error-alert>
        <error-alert :show="itemSinStockSuficiente" @close="removeItemsByStock" :mensaje="noAforoMensaje"></error-alert>
    </default-layout>
</template>

<script>
// import axios from 'axios'
// import DefaultLayout from '../layouts/DefaultLayout.vue'
import ReservaItem from '../components/ReservaItem.vue'
import ApiService from '../services/ApiService'
import ErrorAlert from '../components/ErrorAlert.vue'
import FormularioCompraHorizontal from '../components/FormularioCompraHorizontal.vue';
import moment from 'moment';

export default {
    components: {
        // DefaultLayout,
        ReservaItem,
        ErrorAlert,
        FormularioCompraHorizontal
    },
    data() {
        return {
            // items:[]
            redsysUrl: process.env.VUE_APP_REDSYS_URL,
            Ds_SignatureVersion: null,
            Ds_MerchantParameters: null,
            Ds_Signature: null,
            Ds_Mount: '0',
            clienteNombre: null,
            idCliente: null,
            country: "ES",
            tipoId: "DNI",
            emailCliente: null,
            emailConfirmation: null,
            telefonoCliente: null,
            provinciaCliente: null,
            zipCodeCliente: null,
            aceptoTerminos: false,
            aceptoNewsletter: false,
            loading: false,
            entradas: [],
            redireccionar: false,
            itemRepetido: false,
            plazasParaEsteEvento: 0,
            itemSinStockSuficiente: false,
            noAforoMensaje: null,
            horarioIdToMove: null,
            idVisitaNext: null,
            moment: moment,
            processing: false,
            sending: false
        }
    },
    methods: {
        agregarSeguro() {
            let $vm = this;
            $vm.$store.commit('addCancelToItems')
        },
        quitarSeguro() {
            let $vm = this;
            $vm.$store.commit('removeCancelToItems');
        },
        goToCalendar(event) {
            let $vm = this;
            if ($vm.items.length == 0) {
                if(event.idVisita.length < 12){
                    $vm.$router.push(`/castillos/reservar/idvisita:${event.idVisita}?monumento=${event.monumento}&cancel=true`);
                }
                else{
                    $vm.$router.push(`/castillos/reservar/idvisita:${event.idVisita}?cancel=true`);
                }
            }
        },
        validarFechaDeEntrada(fechaDeEntrada) {
            let now = moment();

            const horaDeUltimoPase = 20;
            const minutosDeUltimoPase = 0;
            let ticketDate = moment( fechaDeEntrada.substring( 0, 19 ) ).set( {hour: horaDeUltimoPase, minutes: minutosDeUltimoPase} );

            return true;
            // if( now.isAfter( ticketDate ) ){
            //     return false
            // }
            // else{
            //     return true;
            // }
        },
        requestClientInformation() {
            let $vm = this;
            $vm.sending = true;
            let accessToken = this.$store.getters.getAccessToken;

            ApiService.getClientInformation(accessToken)
                .then(res => {
                    $vm.sending = false;
                    $vm.clienteNombre = res.data.nombre;
                    $vm.address = res.data.direccion;
                    $vm.telefonoCliente = res.data.telefono;
                    $vm.idCliente = res.data.nif;
                    $vm.zipCodeCliente = res.data.codPostal;
                    $vm.country = res.data.paisCode;
                    $vm.emailCliente = res.data.email;
                    $vm.emailConfirmation = res.data.email;
                    $vm.provinciaCliente = res.data.provincia
                    $vm.aceptoNewsletter = res.data.boletin;
                    if ($vm.country == 'ES') {
                        $vm.provinciaCliente = $vm.zipCodeCliente.slice(0, 2)
                    }
                    else {
                        $vm.provinciaCliente = 'out';
                    }
                })
                .catch(err => {
                    $vm.sending = false;
                })
        },

        quitarReservas() {
            let $vm = this;
            $vm.$store.commit('removeAllItems');
        },


        removeItemsByStock() {
            let $vm = this;
            let entradasPorQuitar = $vm.entradas.filter(entrada => {
                return entrada.itemID == $vm.horarioIdToMove
            }).map(item => {
                return item.itemUuid;
            })

            let idVisitaNext = $vm.entradas.filter(entrada => {
                return entrada.itemUuid == entradasPorQuitar[0];
            }).map(item => { return item.itemIDVisita })

            if (entradasPorQuitar.length > 0) {
                $vm.$store.commit('removeOldItemsFromCart', entradasPorQuitar);
            }

            $vm.itemSinStockSuficiente = false;

            $vm.$router.push(`/castillos/reservar/idvisita:${idVisitaNext}?cancel=true`)
        },

        async sendToRedsysV2(event){
            let $vm = this;
            let merchantData = []

            this.processing = true;

            try{
                const { clienteNombre, tipoId, emailCliente, zipCodeCliente,
                    idCliente, telefonoCliente, provinciaCode, pais,  community, aceptoNewsletter} = event;
    
                $vm.entradas.forEach(entrada => {
                    let entradaObject = {};
                    entradaObject.horarioId = entrada.itemID;
                    entradaObject.visita = Array.isArray( entrada.itemVisita[0] ) ? entrada.itemVisita[0].texto : entrada.itemVisita;
                    entradaObject.monumento = Array.isArray(entrada.itemMonumento) ? entrada.itemMonumento[0].texto : entrada.itemMonumento;
                    entradaObject.fecha = entrada.itemFecha;
                    entradaObject.horario = entrada.itemHorario;
                    entradaObject.tickets = entrada.itemTickets;
                    entradaObject.total = entrada.itemTotal;
                    entradaObject.comision = entrada.comision;
                    merchantData.push(entradaObject);
                })
    
                if ($vm.entradas.length > 0) {
                    // En esta variable guardamos las entradas que ya caducaron
                    let entradasPorQuitar = $vm.entradas.filter(entrada => {
                        return !$vm.validarFechaDeEntrada(entrada.itemFecha)
                    }).map(item => {
                        return item.itemUuid;
                    })
    
                    if (entradasPorQuitar.length > 0) {
                        window.alert("Cuenta en su carrito con entradas fuera de horario, por lo que se eliminarán de su carrito")
                        $vm.$store.commit('removeOldItemsFromCart', entradasPorQuitar);
                    }
    
                    $vm.entradas = $vm.$store.getters.itemsInCart;
    
                    let entradasAgrupadas = $vm.groupBy($vm.entradas, 'itemID');
    
                    const entradasIds = Object.keys(entradasAgrupadas);
                }
    
    
                if ( $vm.itemSinStockSuficiente == false && $vm.entradas.length ) {
                    $vm.loading = true;
                    // $vm.$refs['confirmModal'].show();
                    let payload = {
                        'mount': $vm.totalAPagar.toFixed(2), 'cliente': clienteNombre, 'email': emailCliente, 'tipoId': tipoId, 'idCliente': idCliente,
                        'phone': telefonoCliente, 'provincia': provinciaCode, 'country': pais, 'cp': zipCodeCliente, 'boletin': aceptoNewsletter, 'idioma': this.userLanguage,
                        'merchantData': $vm.entradas
                    }
                    let result = await ApiService.initRedsys(payload);
                    $vm.Ds_MerchantParameters = result.data.Ds_MerchantParameters;
                    $vm.Ds_SignatureVersion = result.data.Ds_SignatureVersion;
                    $vm.Ds_Signature = result.data.Ds_Signature;
    
                    // console.log( result );
    
                    if( result.data.gratuita == true ){
                        const redsysAnswer = {
                            Ds_MerchantParameters: result.data.Ds_MerchantParameters,
                            Ds_SignatureVersion: result.data.Ds_SignatureVersion,
                            Ds_Signature: result.data.Ds_Signature
                        }
    
                        try{
                            await ApiService.registerFreeVisits( redsysAnswer )
                            // console.log( "Registrar compra")
                            setTimeout(() => this.$router.push(`/carrito-ok?Ds_MerchantParameters=${this.Ds_MerchantParameters}&Ds_SignatureVersion=${this.Ds_SignatureVersion}&Ds_Signature=${this.Ds_Signature}`), 1000);
                            $vm.redireccionar = true;
                        }
                        catch(err){
                            window.alert("Problema al hacer el pago")
                        }
    
                    }
                    else{
                        // window.alert("Será redireccionado a una página externa para completar el pago, no recargue la página");
                        // Envía los datos al sistema redys para ser procesados
                        setTimeout(() => this.$refs.redsys_form.submit(), 1000);
                        $vm.redireccionar = true;
                    }
                }
            }catch( err ){
                console.log( err );
                this.$toast.error( this.$t("default.NOTIFICATIONS.BUY_ERROR") )
            }finally{
                this.processing = false;
            }
        },

        groupBy(xs, key) {
            return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        }
        // async enviarRedsys(){

        //     setTimeout(() => this.$refs.redsys_form.submit(), 1000);
        // }
    },
    async mounted() {
        await this.$store.dispatch('fetchAccessToken');
        let accessToken = this.$store.getters.getAccessToken;
        let $vm = this;

        $vm.provincias = this.$store.getters.provincias;

        if (accessToken != null && accessToken) {
            // Revisamos si el token está aun activo
            ApiService.validateClientToken(accessToken)
                .then(res => {
                    // Pedimos la información de este cliente
                    $vm.requestClientInformation();
                })
                .catch(async err => {
                    if (err.response?.status == 401) {
                        console.log(err.response.data);
                        await this.$store.dispatch('removeAccessToken');
                        // this.$router.push('/clientes/login');
                    }
                    await this.$store.dispatch('removeAccessToken');

                })
        }

    },
    async created() {
        let $vm = this;
        // Preguntamos que entradas hay disponibles en el carrito
        this.$store.commit("resetBoletos")
        $vm.entradas = $vm.$store.getters.itemsInCart;
        console.log( this.entradas );
        // Revisamos que todas las entradas que están guardas en el carrito se puedan comprar todavía
        if ($vm.entradas.length > 0) {
            // En esta variable guardamos las entradas que ya caducaron
            let entradasPorQuitar = $vm.entradas.filter(entrada => {
                return !$vm.validarFechaDeEntrada(entrada.itemFecha)
            }).map(item => {
                return item.itemUuid;
            })

            if (entradasPorQuitar.length > 0) {
                window.alert("Cuenta en su carrito con entradas fuera de horario, por lo que se eliminarán de su carrito")
                $vm.$store.commit('removeOldItemsFromCart', entradasPorQuitar);
            }

            $vm.entradas = $vm.$store.getters.itemsInCart;

            let entradasAgrupadas = $vm.groupBy($vm.entradas.filter( e => e.itemID ), 'itemID');

            const entradasIds = Object.keys(entradasAgrupadas);
            for (let entrada of entradasIds) {
                if (entradasAgrupadas[entrada].length > 1) {
                    $vm.itemRepetido = true;
                }

                let plazasInfo = null;
                try{
                    plazasInfo = await ApiService.getPlazasForDate(entrada, entradasAgrupadas[entrada][0]?.tipo );
                }
                catch( err ){
                    console.log( err );
                }

                $vm.plazasParaEsteEvento = plazasInfo.data.plazas;

                if (this.plazasParaEsteEvento != null) {
                    let plazasPromesa = 0;
                    if (entradasAgrupadas[entrada].length > 1) {
                        let tickets = entradasAgrupadas[entrada].map(entradas => {
                            return entradas.itemTickets;
                        })

                        if (tickets.length > 1) {
                            tickets = tickets.reduce((acc, prev) => acc.concat(prev));
                        }
                        if (tickets.length > 1) {
                            plazasPromesa = tickets.map(ticket => ticket.cantidad).reduce((acc, prev) => acc + prev);
                        }
                        else {
                            plazasPromesa = tickets[0].cantidad;
                        }

                        if (this.plazasParaEsteEvento < plazasPromesa) {
                            $vm.noAforoMensaje = `Lo sentimos, solo nos quedan ${this.plazasParaEsteEvento} plazas para este evento: ${entradasAgrupadas[entrada][0].itemVisita[0].texto}. Usted intenta comprar ${plazasPromesa}`
                            $vm.itemSinStockSuficiente = true;
                            this.horarioIdToMove = entrada;
                            this.idVisitaNext = entradasAgrupadas[entrada][0].itemIDVisita;
                        }
                    }

                    else {
                        let tickets = entradasAgrupadas[entrada].map(entradas => {
                            return entradas.itemTickets;
                        })

                        tickets = tickets[0];
                        if (tickets.length > 1) {
                            plazasPromesa = tickets.map(ticket => ticket.cantidad).reduce((acc, prev) => acc + prev);
                        }
                        else {
                            plazasPromesa = tickets[0].cantidad;
                        }

                        if (this.plazasParaEsteEvento < plazasPromesa) {
                            $vm.noAforoMensaje = `Lo sentimos, solo nos quedan ${this.plazasParaEsteEvento} plazas para este evento: ${entradasAgrupadas[entrada][0].itemVisita[0].texto}. Usted intenta comprar ${plazasPromesa}`
                            $vm.itemSinStockSuficiente = true;
                            this.horarioIdToMove = entrada;
                        }

                    }

                }

            }
        }

        $vm.Ds_Mount = $vm.$store.getters.total.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFracionDigits: 2 });

    },
    computed: {
        existItemsInCart(){
            return this.$store.getters.itemsInCart.length;
        },
        idiomaIndex() {
            let $vm = this;
            return $vm.$store.getters.idiomaIndex;
        },
        items() {
            let $vm = this;
            // $vm.entradas = $vm.$store.getters.itemsInCartByGroup.map( t => t.information );
            // return $vm.entradas;
            return $vm.$store.getters.itemsInCartByGroup;
        },
        cancelable() {
            let $vm = this;
            if ($vm.$store.getters.totalCancelacion > 0) {
                return true;

            }
            else
                return false;
        },
        seguroDeCancelacion() {
            let $vm = this;
            return $vm.$store.getters.seguroDeCancelacion;
        },
        totalAPagar() {
            let $vm = this;
            // $vm.Ds_Mount = $vm.$store.getters.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFracionDigits: 2 });

            
            return Number(($vm.$store.getters.total + ( (( this.$store.getters.seguroDeCancelacion) ? $vm.$store.getters.totalCancelacion : 0) ?? 0 ) - ($vm.$store.getters.descuentoPorPromociones ?? 0)).toFixed( 2 )) ;
        },
        descuentoYaAplicado(){
            return this.$store.getters.descuentoPorPromociones ?? 0
        },
        totalCancelacion() {
            let $vm = this;
            let total = $vm.$store.getters.totalCancelacion;
            // total = 0;
            return (total).toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFracionDigits: 2 });
        },
        userLanguage(){
            return this.$store.getters.getIdioma ?? 'es'
        }
    }
}
</script>

<style scoped lang="css">
.loader-component {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba( 255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

</style>