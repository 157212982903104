var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "default-layout",
    [
      _c(
        "div",
        {
          staticClass: "spainhn-solid-red-background d-flex",
          staticStyle: { "min-height": "8vh", width: "100%" },
        },
        [
          _c("div", { staticClass: "d-flex ps-5 my-auto" }, [
            _c(
              "p",
              {
                staticClass: "my-auto pb-0 mb-0 spainhn-text",
                attrs: { role: "button" },
              },
              [_vm._v(_vm._s(_vm.$t("default.CART_DESCRIPTION_TEXT")) + " >>")]
            ),
            _c(
              "p",
              { staticClass: "my-auto pb-0 mb-0 ms-2 spainhn-small-text" },
              [
                _c("strong", [
                  _vm._v(_vm._s(_vm.$t("default.CART_RESUME_TEXT"))),
                ]),
              ]
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "container-fluid" },
        [
          _c(
            "div",
            {
              staticClass: "loader-component",
              class: { "d-none": !_vm.processing && !_vm.sending },
              attrs: { id: "loader" },
            },
            [
              _c("img", {
                staticClass: "animated flip infinite",
                staticStyle: { height: "220px", width: "auto" },
                attrs: {
                  src: require("../assets/SimboloCastilloRojoCorporativa.svg"),
                },
              }),
            ]
          ),
          _c(
            "mdb-row",
            { staticClass: "px-md-4 py-2" },
            [
              _vm._l(_vm.items, function (item, index) {
                return _c("reserva-item", {
                  key: index,
                  attrs: { item: item },
                  on: {
                    itemRemoved: _vm.goToCalendar,
                    eliminando: (evt) => (_vm.sending = evt),
                  },
                })
              }),
              _c("div", { staticClass: "clearfix mt-2" }, [
                _c("div", { staticClass: "float-right" }, [
                  _c(
                    "h6",
                    {
                      staticClass: "text-end fw-bold",
                      class: { "d-none": _vm.descuentoYaAplicado === 0 },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("default.MENSAJE_IMPORTE_SUBTOTAL")) + " "
                      ),
                    ]
                  ),
                  _c(
                    "h6",
                    {
                      staticClass:
                        "text-end fw-bold text-decoration-line-through",
                      class: { "d-none": _vm.descuentoYaAplicado === 0 },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          (
                            _vm.totalAPagar + (_vm.descuentoYaAplicado ?? 0)
                          ).toLocaleString("es-ES", {
                            style: "currency",
                            currency: "EUR",
                          })
                        )
                      ),
                    ]
                  ),
                  _c(
                    "h6",
                    {
                      staticClass: "text-end fw-bold",
                      class: { "d-none": _vm.descuentoYaAplicado === 0 },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("default.MENSAJE_IMPORTE_DESCUENTO")) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "h6",
                    {
                      staticClass: "text-end fw-bold",
                      class: { "d-none": _vm.descuentoYaAplicado === 0 },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.descuentoYaAplicado.toLocaleString("es-ES", {
                            style: "currency",
                            currency: "EUR",
                          })
                        )
                      ),
                    ]
                  ),
                  _c("h4", { staticClass: "text-end fw-bold" }, [
                    _vm._v(_vm._s(_vm.$t("default.MENSAJE_IMPORTE_TOTAL"))),
                  ]),
                  _c("h3", { staticClass: "text-end fw-bold" }, [
                    _vm._v(
                      _vm._s(
                        _vm.totalAPagar.toLocaleString("es-ES", {
                          style: "currency",
                          currency: "EUR",
                        })
                      )
                    ),
                  ]),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "mdb-row",
            { staticClass: "justify-content-md-center" },
            [
              _c("mdb-col", { staticClass: "pt-3", attrs: { col: "12" } }, [
                _c("div", { class: { "d-none": !_vm.cancelable } }, [
                  _c("div", { class: { "d-none": _vm.seguroDeCancelacion } }, [
                    _c(
                      "div",
                      {
                        staticClass: "border px-5 py-4 border-warning mb-3",
                        staticStyle: {
                          "background-color": "rgba(252, 248, 134, 0.5)",
                        },
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-1 col-12 my-auto" },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "my-auto text-danger mx-auto",
                                    attrs: {
                                      icon: "fa-circle-exclamation",
                                      size: "3x",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "col-10 mx-auto" }, [
                            _c("p", [
                              _c("strong", { staticClass: "text-spainhn" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("default.MENSAJE_IMPREVISTO"))
                                ),
                              ]),
                            ]),
                            _c("p", { staticClass: "text-spainhn" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("default.MENSAJE_SIN_GARANTIA")
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "border px-5 py-4 border-info mt-3 mb-3",
                        staticStyle: {
                          "background-color": "rgba(204, 255, 255, 0.5)",
                        },
                      },
                      [
                        _c("div", { staticClass: "row text-start" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-8 col-12 text-center" },
                            [
                              _c("p", [
                                _c(
                                  "strong",
                                  { staticClass: "spainhn-subtitle-2-black" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "default.MENSAJE_TITULO_GARANTIA"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                              _c("p", { staticClass: "text-spainhn" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "default.MENSAJE_EXPLICACION_DEVOLUCION"
                                    )
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-4 col-12 text-center" },
                            [
                              _c(
                                "p",
                                {
                                  staticClass: "spainhn-text",
                                  staticStyle: { "font-weight": "600" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("default.MENSAJE_GARANTIA_2"))
                                  ),
                                ]
                              ),
                              _c(
                                "p",
                                { staticClass: "spainhn-subtitle-2-black" },
                                [_vm._v(_vm._s(_vm.totalCancelacion) + " €")]
                              ),
                              _c(
                                "mdb-btn",
                                {
                                  staticClass: "spainhn-button",
                                  attrs: { color: "indigo" },
                                  on: { click: _vm.agregarSeguro },
                                },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "default.MENSAJE_AGREGAR_GARANTIA"
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { class: { "d-none": !_vm.seguroDeCancelacion } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "border px-md-5 px-3 py-4 border-success mb-3",
                        staticStyle: {
                          "background-color": "rgba(204, 255, 204, 0.5)",
                        },
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-1 col-12 my-auto" },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "my-auto mx-auto text-success",
                                    attrs: {
                                      icon: "fa-circle-check",
                                      size: "3x",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "col-10 mx-auto" }, [
                            _c("p", [
                              _c("strong", { staticClass: "text-spainhn" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "default.MENSAJE_TITULO_CONFIRMACION_GARANTIA"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _c("p", { staticClass: "text-spainhn" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("default.MENSAJE_IMPORTE_GARANTIA")
                                ) + " "
                              ),
                              _c("strong", [
                                _vm._v(_vm._s(_vm.totalCancelacion) + " €"),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "border px-md-5 px-3 py-4 border-info mt-3 mb-3",
                        staticStyle: {
                          "background-color": "rgba(204, 255, 255, 0.5)",
                        },
                      },
                      [
                        _c("div", { staticClass: "row text-start" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-8 col-12 text-center" },
                            [
                              _c("p", [
                                _c(
                                  "strong",
                                  { staticClass: "spainhn-subtitle-2-black" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "default.MENSAJE_TITULO_GARANTIA"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                              _c("p", { staticClass: "text-spainhn" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "default.MENSAJE_EXPLICACION_DEVOLUCION"
                                    )
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-md-4 col-12 text-center my-auto",
                              attrs: { role: "button" },
                              on: { click: _vm.quitarSeguro },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "mb-2" },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "fa-trash", size: "3x" },
                                  }),
                                ],
                                1
                              ),
                              _c("small", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("default.MENSAJE_QUITAR_GARANTIA")
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c("mdb-row", { class: { "d-none": !_vm.existItemsInCart } }, [
            _c(
              "div",
              { staticClass: "col-12 spainhn-text mt-5" },
              [
                _c("formulario-compra-horizontal", {
                  on: { dataReady: _vm.sendToRedsysV2 },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "container-fluid spainhn-bg-gris-cajas w-100 py-5 px-4 my-4 rounded",
          class: { "d-none": _vm.items.length },
        },
        [
          _c("div", { staticClass: "spainhn-subtitle" }, [
            _vm._v(_vm._s(_vm.$t("default.CART_EMPTY_CART"))),
          ]),
          _c(
            "div",
            [
              _c(
                "mdb-btn",
                {
                  attrs: { size: "lg", color: "danger" },
                  on: { click: () => _vm.$router.push("/") },
                },
                [
                  _c("span", { staticClass: "fw-bold" }, [
                    _vm._v(_vm._s(_vm.$t("default.CART.BUY_MORE"))),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: { "aria-label": "", "hide-header": "", "hide-footer": "" },
          model: {
            value: _vm.redireccionar,
            callback: function ($$v) {
              _vm.redireccionar = $$v
            },
            expression: "redireccionar",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "border px-2 py-4 border-success mb-3",
              staticStyle: { "background-color": "rgba(204, 255, 204, 0.5)" },
            },
            [
              _c("div", { staticClass: "d-flex" }, [
                _c("div", { staticClass: "d-flex mx-auto" }, [
                  _c("p", { staticClass: "my-auto" }, [
                    _c("strong", { staticClass: "text-spainhn" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("default.CART.REDIRECT")) + " "
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c("b-modal", { on: { ok: _vm.quitarReservas } }, [
        _c("div", [
          _vm._v(" " + _vm._s(_vm.$t("default.CART_REMOVE_ALL")) + " "),
        ]),
      ]),
      _c(
        "b-form",
        {
          ref: "redsys_form",
          attrs: {
            name: "redsys_form",
            action: _vm.redsysUrl,
            method: "post",
            id: "redsys_form",
          },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.Ds_SignatureVersion,
                expression: "Ds_SignatureVersion",
              },
            ],
            attrs: { type: "text", name: "Ds_SignatureVersion", hidden: "" },
            domProps: { value: _vm.Ds_SignatureVersion },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.Ds_SignatureVersion = $event.target.value
              },
            },
          }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.Ds_MerchantParameters,
                expression: "Ds_MerchantParameters",
              },
            ],
            attrs: { type: "text", name: "Ds_MerchantParameters", hidden: "" },
            domProps: { value: _vm.Ds_MerchantParameters },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.Ds_MerchantParameters = $event.target.value
              },
            },
          }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.Ds_Signature,
                expression: "Ds_Signature",
              },
            ],
            attrs: { type: "text", name: "Ds_Signature", hidden: "" },
            domProps: { value: _vm.Ds_Signature },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.Ds_Signature = $event.target.value
              },
            },
          }),
        ]
      ),
      _c("error-alert", {
        attrs: {
          show: _vm.itemRepetido,
          mensaje: _vm.$t("default.CART.DUPLICATED"),
        },
        on: { close: () => (_vm.itemRepetido = false) },
      }),
      _c("error-alert", {
        attrs: {
          show: _vm.itemSinStockSuficiente,
          mensaje: _vm.noAforoMensaje,
        },
        on: { close: _vm.removeItemsByStock },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }